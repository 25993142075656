/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-glow: conic-gradient(
          from 180deg at 50% 50%,
          #3b82f680 0deg,
          #8b5cf680 55deg,
          #8b5cf680 120deg,
          #3b82f680 160deg,
          transparent 360deg
  );
  --secondary-glow: radial-gradient(
          rgba(56, 182, 255, 0.2),
          rgba(56, 182, 255, 0)
  );
}

/* Base styles */
body {
  background: #000;
  color: white;
  min-height: 100vh;
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
}

/* Hide scrollbar while keeping functionality */
::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(59, 130, 246, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(59, 130, 246, 0.5);
}

/* Font enhancements */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Plus Jakarta Sans', 'Inter', system-ui, sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}

/* Custom blur and glass effects */
.backdrop-blur-glass {
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

/* Animated gradient text */
.animated-gradient-text {
  background-image: linear-gradient(90deg, #fff, #3b82f6, #fff);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 8s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

/* Enhanced transition effects */
.page-transition-enter {
  opacity: 0;
  transform: translateY(20px);
}
.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}
.page-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

/* Glow effects */
.glow-blue {
  box-shadow: 0 0 15px 5px rgba(59, 130, 246, 0.15);
}

.glow-purple {
  box-shadow: 0 0 15px 5px rgba(139, 92, 246, 0.15);
}

.glow-text {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Animation utilities */
.hover-float {
  transition: transform 0.3s ease;
}

.hover-float:hover {
  transform: translateY(-8px);
}

/* Pulse animation */
@keyframes pulse-slow {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse-slow {
  animation: pulse-slow 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* 3D button effect */
.btn-3d {
  position: relative;
  transition: all 0.3s ease;
  transform: translateY(0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.btn-3d:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.3), 0 4px 6px -2px rgba(59, 130, 246, 0.1);
}

.btn-3d:active {
  transform: translateY(1px);
}

/* Grid pattern background */
.grid-pattern {
  background-image: linear-gradient(rgba(255, 255, 255, 0.03) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
  background-size: 40px 40px;
}

/* Fancy link underline animation */
.link-underline {
  position: relative;
}

.link-underline::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background-image: linear-gradient(90deg, #3b82f6, #8b5cf6);
  transition: width 0.3s ease;
}

.link-underline:hover::after {
  width: 100%;
}